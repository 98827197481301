import { getCardInfoApi, getUserCardListApi } from '@/api/card';
import router from '@/router';
// import { amountFmt } from '@/utils/filter';
import { phoneFmt } from '@/utils/format';
export default {
  namespaced: true,
  state: {
    cardId: '',
    cardList: [],
    cardInfo: {
      cardNo: '',
      serialNo: '',
    },
  },
  mutations: {
    SET_CARD_INFO(state, payload) {
      localStorage.setItem('CARD_INFO', JSON.stringify(payload));
      state.cardInfo = payload;
    },
    SET_CARD_LIST(state, payload) {
      localStorage.setItem('CARD_LIST', JSON.stringify(payload));
      state.cardList = payload;
    },
    SET_CARD_ID(state, payload) {
      localStorage.setItem('CARD_ID', payload);
      state.cardId = payload;
    },
  },
  actions: {
    // 设置卡信息
    setCardInfo({ commit }, payload) {
      commit('SET_CARD_INFO', payload);
    },
    // 获取卡信息
    /*
     * payload undefined || { check: true } || { cardNo, serialNo }
     * undefined || { check: true } 使用state中的cardNo和serialNo
     * { cardNo, serialNo } 使用参数payload中的cardNo和serialNo
     */
    async getCardInfoAction({ state, dispatch }, payload) {
      console.log(payload, '-----------');
      try {
        if (payload && !payload.isList) {
          const cardId = localStorage.getItem('CARD_ID');
          if (!cardId) {
            router.replace({
              name: 'CardList',
              query: {
                status: 1,
              },
            });
            return;
          }
        }

        let memberInfo = JSON.parse(localStorage.getItem('MEMBER_INFO'));
        let params = {
          phone: memberInfo.phone,
          orgId: localStorage.getItem('ORG_ID'),
        };
        if (!payload || payload.check) {
          params = {
            ...params,
            cardNo: state.cardInfo.cardNo,
            cardId: state.cardInfo.cardId,
          };
          if (state.cardInfo.serialNo) {
            params.serialNo = state.cardInfo.serialNo;
          }
        } else {
          params = {
            ...params,
            cardNo: payload.cardNo,
            cardId: payload.cardId,
          };
          if (payload.serialNo) {
            params.serialNo = payload.serialNo;
          }
        }
        const data = await getCardInfoApi({
          ...params,
        });
        if (data.code === 200) {
          dispatch('setCardInfo', {
            ...data.content,
          });
        }
        // 是否判断status
        if (payload && payload.check) {
          dispatch('checkPageTo', data.content.belongStatus);
        }
        return data;
      } catch (error) {
        console.log(error);
        if (payload && payload.check) {
          dispatch('checkPageTo', -1);
        }
        return false;
      }
    },
    // 判断：卡片是否属于当前用户
    checkPageTo(ctx, status) {
      console.log('跳转列表checkPageTo');
      console.log('cardInfo status', status);
      if (status === 0 || status === 2) {
        // 未绑定用户/不属于当前用户
        router.replace({
          name: 'CardList',
          query: {
            status,
          },
        });
      } else if (status === 1) {
        // ctx.getCardListAction()
        // if (ctx.cardList.length === 1) {
        // }
        // 归属当前用户
        router.replace({
          name: 'User',
        });
      } else {
        console.log(1213123123);
        // 没有获取到卡信息
        router.replace({
          name: 'CardList',
          query: {
            status: -1,
          },
        });
      }
    },
    // 获取卡列表
    async getCardListAction({ commit }) {
      try {
        let memberInfo = JSON.parse(localStorage.getItem('MEMBER_INFO'));
        const data = await getUserCardListApi({
          phone: memberInfo.phone,
          orgId: localStorage.getItem('ORG_ID'),
        });
        if (data.code === 200) {
          console.log(data.content);

          commit('SET_CARD_LIST', data.content || []);
          return data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    menuList(state) {
      let menus = [
        {
          name: '购买限制',
          icon: 'icongoumaixianzhi1',
          urlName: 'BuyLimit',
          query: {},
          auth: state.cardInfo.status != 2,
        },
        {
          name: '交易记录',
          icon: 'iconjiaoyijilu',
          urlName: 'RechargeRecord',
          query: {},
          auth: true,
        },

        {
          name: '取消挂失',
          icon: 'iconjieguashi',
          urlName: 1,
          auth: state.cardInfo.status == 3,
        },
        {
          name: '取消补卡',
          icon: 'iconbuka',
          urlName: 2,
          auth: state.cardInfo.status == 4,
        },
        {
          name: '挂失',
          icon: 'iconguahi',
          urlName: 3,
          auth: state.cardInfo.status != 3 && state.cardInfo.status != 2,
        },
        {
          name: '补卡',
          icon: 'iconbuka',
          urlName: 4,
          auth: state.cardInfo.status != 4 && state.cardInfo.status != 2,
        },
        {
          name: '联系管理员',
          icon: 'iconkefu',
          urlName: 9,
          auth: state.cardInfo.status != 2,
        },
        {
          name: '密码支付',
          icon: 'iconwukazhifutubiao',
          urlName: 'CardlessPayment',
          query: {},
          auth: state.cardInfo.electronicCardPay, // 商户开通无卡支付，才展示
          value: ['未开通', '已开通'][Number(state.cardInfo.openState)],
        },
        {
          name: '换绑手机号',
          icon: 'iconhuanbangshoujihao',
          // urlName: 'ChangePhone',
          urlName: 6,
          query: {},
          auth: true,
          value: phoneFmt(state.cardInfo.phone),
        },
        {
          name: '切换卡片',
          icon: 'iconqiehuankapian',
          urlName: 'CardList',
          query: { status: state.cardInfo.belongStatus, mode: 'card' },
          auth: true,
        },
        {
          name: '退出登录',
          icon: 'icontuichudenglu',
          urlName: 5,
          auth: true,
        },
      ];
      return menus.filter((m) => {
        return m.auth;
      });
    },
    messageBox(state) {
      return {
        1: {
          title: '确定取消挂失',
          message: '取消挂失后，储值卡可正常购买，卡片遗失后建议申请补卡~',
        },
        2: {
          title: '确定取消补卡',
          message: '取消补卡后，储值卡可正常购买，卡片遗失后建议申请补卡~',
        },
        3: {
          title: '确定挂失卡片？',
          message: `挂失只针对实体卡有效，无卡支付仍可正常使用`,
        },
        4: {
          title: '确定申请补卡?',
          message: `补卡只针对实体卡有效`,
          // message: `补卡将扣除${amountFmt(
          //   state.cardInfo.productionCost || 0
          // )}元工本费~`,
        },

        5: {
          title: '',
          message: `是否确认退出当前登录？`,
        },
        6: {
          title: '确定换绑手机号？',
          message: `换绑后，现手机号 ${phoneFmt(
            state.cardInfo.phone
          )} 将不能用于支付`,
          confirmButtonText: '确认更换',
          cancelButtonText: '我再想想',
        },
      };
    },
  },
};
